import {
  faArrowRight,
  faGraduationCap,
  faRupee,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AxiosClient from "../client/AxiosClient";
import { API_RECOMMENDED_COURSES } from "../ApiEndPoints";

export default function CoursesRowWithAction() {
  const [courses, setCourses] = useState([]);
  const serverUrl = process.env.REACT_APP_SERVER_BASE_URL;

  useEffect(() => {
    AxiosClient.get(API_RECOMMENDED_COURSES)
      .then((response) => {
        const listData = response.data.data;
        console.log(listData);
        setCourses(listData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <section class="">
      <div class="container">
        <div class="card p-2 py-3 p-md-5 border-0 bg-lightaccent bg-style-1 rounded-4">
          <div class="row">
            <div class="col-md-12 col-lg-3">
              <h2 class="sec-title-sm">Recommended Course</h2>
              <p class="sec-short-desc">
                Explore our top courses recommended by our top faculties
              </p>
              <Link
                to="/under-development"
                class="btn btn-primary theme-btn bg-primary me-2 d-none d-lg-inline-block"
              >
                View All
                <span class="_icon">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </Link>
            </div>

            <div class="col-md-12 col-lg-9">
              <div class="row">
                {courses.map((row, index) => {
                  return (
                    <div class="col-md-6 col-lg-4">
                      <div class="card cc-style-1 mb-3 scale-on-hover shadow-on-hover">
                        <Link to="#" class="text-decoration-none">
                          <div class="img-wrap">
                            <img
                              src={serverUrl + row.thumbnail}
                              class="card-img-top img-fluid"
                              alt=""
                            />
                          </div>
                          <div class="card-body px-0">
                            <div class="d-flex _partner align-items-center">
                              <div class="_partner_icon_wrap">
                                <FontAwesomeIcon
                                  icon={faGraduationCap}
                                  className="_highligt_icon"
                                  color="#0056d2"
                                />
                              </div>
                              <span class="_partner_name">
                                By {row.instructor_name}
                              </span>
                            </div>

                            <h5 class="card-title">{row.title}</h5>

                            <small class="_highlight d-block">
                              <FontAwesomeIcon
                                icon={faRupee}
                                className="_highligt_icon"
                                color="#0056d2"
                              />
                              <span class="_highligt_title">
                                {row.discount_price != null ? (
                                  <>
                                    <del className="text-muted">
                                      {row.price}
                                    </del>{" "}
                                    <span className="ms-2">
                                      {row.discount_price}
                                    </span>
                                  </>
                                ) : (
                                  row.price
                                )}
                              </span>
                            </small>
                            <small class="_category d-block">
                              {row.category_name}
                            </small>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })}

                {/* <div class="col-md-6 col-lg-4">
                  <div class="card cc-style-1 mb-3 scale-on-hover shadow-on-hover">
                    <Link to="#" class="text-decoration-none">
                      <div class="img-wrap position-relative">
                        <img
                          src="/img/certificates/img-1.png"
                          class="card-img-top img-fluid"
                          alt=""
                        />
                        <span class="_badge badge badge-transparent text-uppercase position-absolute bg-danger">
                          <i class="fa-brands fa-gripfire"></i> New
                        </span>
                      </div>
                      <div class="card-body px-0">
                        <div class="d-flex _partner align-items-center">
                          <div class="_partner_icon_wrap">
                            <img
                              src="/img/partner/google.png"
                              class="_partner_icon img-fluid"
                            />
                          </div>
                          <span class="_partner_name">Google</span>
                        </div>

                        <h5 class="card-title">
                          Vote for Miraex Swisscom Startup Challenge 2023
                        </h5>

                        <small class="_highlight d-block">
                          <FontAwesomeIcon
                            icon={faUserTie}
                            className="_highligt_icon"
                          />
                          <span class="_highligt_title">Professional</span>
                        </small>
                        <small class="_category d-block">Web Development</small>
                      </div>
                    </Link>
                  </div>
                </div>
                <div class="col-md-6 col-lg-4">
                  <div class="card cc-style-1 mb-3 scale-on-hover shadow-on-hover">
                    <Link to="#" class="text-decoration-none">
                      <div class="img-wrap">
                        <img
                          src="/img/certificates/img-4.png"
                          class="card-img-top img-fluid"
                          alt=""
                        />
                      </div>
                      <div class="card-body px-0">
                        <div class="d-flex _partner align-items-center">
                          <div class="_partner_icon_wrap">
                            <img
                              src="/img/partner/google.png"
                              class="_partner_icon img-fluid"
                            />
                          </div>
                          <span class="_partner_name">Google</span>
                        </div>

                        <h5 class="card-title">
                          Vote for Miraex Swisscom Startup Challenge 2023
                        </h5>

                        <small class="_highlight d-block">
                          <FontAwesomeIcon
                            icon={faUserTie}
                            className="_highligt_icon"
                          />
                          <span class="_highligt_title">Professional</span>
                        </small>
                        <small class="_category d-block">Web Development</small>
                      </div>
                    </Link>
                  </div>
                </div> */}

                <div class="col-12 text-center mt-3 d-lg-none">
                  <Link to="#" class="btn btn-outline-light theme-btn">
                    View All
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import {
  faArrowRight,
  faGraduationCap,
  faRupee,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AxiosClient from "../client/AxiosClient";
import { API_POPULAR_COURSES } from "../ApiEndPoints";

export default function CoursesGrid1() {
  const [popularCourses, setPopularCourses] = useState([]);
  const serverUrl = process.env.REACT_APP_SERVER_BASE_URL;

  useEffect(() => {
    AxiosClient.get(API_POPULAR_COURSES)
      .then((response) => {
        const listData = response.data.data;
        console.log(listData);
        setPopularCourses(listData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <section className="">
      <div className="container">
        <p className="sec-sub-title">
          India's Best Industry Recognized Online Certification
        </p>
        <h2 className="sec-title">Most Popular Courses</h2>
        <p className="sec-short-desc">Explore our most popular courses.</p>

        <div>
          <div className="row">
            {popularCourses.map((row, index) => {
              return (
                <div className="col-sm-6 col-md-6 col-lg-3">
                  <div className="card cc-style-1 mb-3 scale-on-hover shadow-on-hover">
                    <Link to="#" className="text-decoration-none">
                      <div className="img-wrap position-relative">
                        <img
                          src={serverUrl + row.thumbnail}
                          className="card-img-top img-fluid"
                          alt=""
                        />
                        {/* <span className="_badge badge badge-transparent text-uppercase position-absolute bg-danger">
                          <i className="fa-brands fa-gripfire"></i> New
                        </span> */}
                      </div>
                      <div className="card-body px-0">
                        <div className="d-flex _partner align-items-center">
                          <div className="_partner_icon_wrap text-center">
                            {/* <img
                              src="/img/partner/google.png"
                              className="_partner_icon img-fluid"
                            /> */}
                            <FontAwesomeIcon
                              icon={faGraduationCap}
                              className="_highligt_icon"
                              color="#0056d2"
                            />
                          </div>
                          <span className="_partner_name">
                            By {row.instructor_name}
                          </span>
                        </div>

                        <h5 className="card-title">{row.title}</h5>

                        <small className="_highlight d-block">
                          <FontAwesomeIcon
                            icon={faRupee}
                            className="_highligt_icon"
                            color="#0056d2"
                          />
                          <span className="_highligt_title">
                            {row.discount_price != null ? (
                              <>
                                <del className="text-muted">{row.price}</del>{" "}
                                <span className="ms-2">
                                  {row.discount_price}
                                </span>
                              </>
                            ) : (
                              row.price
                            )}
                          </span>
                        </small>
                        <small className="_category d-block">
                          {row.category_name}
                        </small>
                      </div>
                    </Link>
                  </div>
                </div>
              );
            })}
            {/* <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="card cc-style-1 mb-3 scale-on-hover shadow-on-hover">
                <Link to="#" className="text-decoration-none">
                  <div className="img-wrap position-relative">
                    <img
                      src="/img/certificates/img-1.png"
                      className="card-img-top img-fluid"
                      alt=""
                    />
                    <span className="_badge badge badge-transparent text-uppercase position-absolute bg-danger">
                      <i className="fa-brands fa-gripfire"></i> New
                    </span>
                  </div>
                  <div className="card-body px-0">
                    <div className="d-flex _partner align-items-center">
                      <div className="_partner_icon_wrap">
                        <img
                          src="/img/partner/google.png"
                          className="_partner_icon img-fluid"
                        />
                      </div>
                      <span className="_partner_name">Google</span>
                    </div>

                    <h5 className="card-title">
                      Vote for Miraex Swisscom Startup Challenge 2023
                    </h5>

                    <small className="_highlight d-block">
                      <FontAwesomeIcon
                        icon={faUserTie}
                        className="_highligt_icon"
                        color="#0056d2"
                      />
                      <span className="_highligt_title">Professional</span>
                    </small>
                    <small className="_category d-block">Web Development</small>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="card cc-style-1 mb-3 scale-on-hover shadow-on-hover">
                <Link to="#" className="text-decoration-none">
                  <div className="img-wrap">
                    <img
                      src="/img/certificates/img-2.png"
                      className="card-img-top img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="card-body px-0">
                    <div className="d-flex _partner align-items-center">
                      <div className="_partner_icon_wrap">
                        <img
                          src="/img/partner/google.png"
                          className="_partner_icon img-fluid"
                        />
                      </div>
                      <span className="_partner_name">Google</span>
                    </div>

                    <h5 className="card-title">
                      Vote for Miraex Swisscom Startup Challenge 2023
                    </h5>

                    <small className="_highlight d-block">
                      <FontAwesomeIcon
                        icon={faUserTie}
                        className="_highligt_icon"
                      />
                      <span className="_highligt_title">Professional</span>
                    </small>
                    <small className="_category d-block">Web Development</small>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="card cc-style-1 mb-3 scale-on-hover shadow-on-hover">
                <Link to="#" className="text-decoration-none">
                  <div className="img-wrap position-relative">
                    <img
                      src="/img/certificates/img-1.png"
                      className="card-img-top img-fluid"
                      alt=""
                    />
                    <span className="_badge badge badge-transparent text-uppercase position-absolute bg-danger">
                      <i className="fa-brands fa-gripfire"></i> New
                    </span>
                  </div>
                  <div className="card-body px-0">
                    <div className="d-flex _partner align-items-center">
                      <div className="_partner_icon_wrap">
                        <img
                          src="/img/partner/google.png"
                          className="_partner_icon img-fluid"
                        />
                      </div>
                      <span className="_partner_name">Google</span>
                    </div>

                    <h5 className="card-title">
                      Vote for Miraex Swisscom Startup Challenge 2023
                    </h5>

                    <small className="_highlight d-block">
                      <FontAwesomeIcon
                        icon={faUserTie}
                        className="_highligt_icon"
                        color="#0056d2"
                      />
                      <span className="_highligt_title">Professional</span>
                    </small>
                    <small className="_category d-block">Web Development</small>
                  </div>
                </Link>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="card cc-style-1 mb-3 scale-on-hover shadow-on-hover">
                <Link to="#" className="text-decoration-none">
                  <div className="img-wrap">
                    <img
                      src="/img/certificates/img-4.png"
                      className="card-img-top img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="card-body px-0">
                    <div className="d-flex _partner align-items-center">
                      <div className="_partner_icon_wrap">
                        <img
                          src="/img/partner/google.png"
                          className="_partner_icon img-fluid"
                        />
                      </div>
                      <span className="_partner_name">Google</span>
                    </div>

                    <h5 className="card-title">
                      Vote for Miraex Swisscom Startup Challenge 2023
                    </h5>

                    <small className="_highlight d-block">
                      <FontAwesomeIcon
                        icon={faUserTie}
                        className="_highligt_icon"
                      />
                      <span className="_highligt_title">Professional</span>
                    </small>
                    <small className="_category d-block">Web Development</small>
                  </div>
                </Link>
              </div>
            </div>*/}

            <div className="col-12">
              <a className="btn btn-primary theme-btn bg-primary me-md-2 d-block d-md-inline-block">
                Show 8 more
              </a>
              <Link
                to="/under-development"
                className="btn btn-outline-pinkimary theme-btn d-block d-md-inline-block mt-2 mt-md-0"
              >
                View all
                <span className="_icon">
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

import { Link } from "react-router-dom";

export default function LogoRow() {
  return (
    <section class="bg-lightgrey">
      <div class="container">
        <div class="title text-center">
          <h3>
            We collaborate with{" "}
            <span class="text-primary">
              325+ leading universities and companies
            </span>
          </h3>
        </div>
        <div class="mt-5">
          <div class="d-flex flex-wrap justify-content-center align-items-center partners">
            <Link href="#" class="px-4 py-2 partner-logo">
              <img src="/img/clients/illinois-3.png" />
            </Link>
            <Link href="#" class="px-4 py-2 partner-logo">
              <img src="/img/clients/imperial.png" />
            </Link>
            <Link href="#" class="px-4 py-2 partner-logo">
              <img src="/img/clients/google.png" />
            </Link>
            <Link href="#" class="px-4 py-2 partner-logo">
              <img src="/img/clients/duke-3.png" />
            </Link>
            <Link href="#" class="px-4 py-2 partner-logo">
              <img src="/img/clients/1000px-IBM_logo.svg.png" />
            </Link>
            <Link href="#" class="px-4 py-2 partner-logo">
              <img src="/img/clients/penn.png" />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "./Loader";

export function FilledBtnStyle1({
  label,
  link,
  classes = "",
  typeBtn = false,
  clickHandler,
}) {
  return typeBtn ? (
    <Button
      onClick={clickHandler}
      variant="btn btn-light theme-btn bg-light text-primary"
      className={`${classes}`}
    >
      {label}
    </Button>
  ) : (
    <Link to={link} className={`btn btn-light theme-btn bg-light ${classes}`}>
      {label}
    </Link>
  );
}

export function FilledBtnStyle2({
  label,
  link,
  classes,
  typeBtn = false,
  clickHandler,
}) {
  return typeBtn ? (
    <Button
      onClick={clickHandler}
      className={`btn btn-light theme-btn bg-light ${classes}`}
    >
      {label}
    </Button>
  ) : (
    <Link
      to={link}
      className={`btn btn-primary theme-btn bg-primary ${classes}`}
    >
      {label}
    </Link>
  );
}

export function IconFilledPrimaryBtn({
  label,
  link,
  fontAwesomeIcon,
  classes,
  isBtn = false,
  isLoading = false,
  clickHandler = function () {},
}) {
  return isBtn ? (
    <Button
      className={`btn btn-primary theme-btn bg-primary ${classes}`}
      type="submit"
      onClick={clickHandler}
      disabled={isLoading}
    >
      {isLoading ? (
        <Loader classes="text-white" />
      ) : (
        <>
          {label !== "" && <span className="_label me-2">{label}</span>}
          <FontAwesomeIcon icon={fontAwesomeIcon} />
        </>
      )}
    </Button>
  ) : (
    <Link
      to={link}
      className={`btn btn-primary theme-btn bg-primary ${classes}`}
    >
      {label !== "" && <span className="_label me-2">{label}</span>}
      <span class="_icon">
        <FontAwesomeIcon icon={fontAwesomeIcon} />
      </span>
    </Link>
  );
}

export function FilledSubmitBtnStyle2({
  label,
  link,
  classes,
  isLoading = false,
}) {
  return (
    <Button
      type="submit"
      className={`btn btn-primary theme-btn bg-primary ${classes} text-white`}
      isDisabled={isLoading}
    >
      {label} {isLoading && <Loader classes="text-white" />}
    </Button>
  );
}

export default function LeftIconCard({ iconPath, title = "", desc = "" }) {
  return (
    <div className="icon-card mb-5">
      <div className="_icon_wrap">
        <img src={iconPath} className="invert-img" alt="icon" />
      </div>
      <h5 className="text-white mt-2 mb-0">{title}</h5>
      <p className="text-white">{desc}</p>
    </div>
  );
}

import {
  fab,
  faFacebook,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faCopy, faCopyright } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import NewsletterSection from "./NewsletterSection";

export default function Footer() {
  return (
    <footer class="text-center text-md-start">
      <div class="container">
        <NewsletterSection />
        <div class="row">
          <div class="col-md-3">
            <h5 class="mb-0">Quick Links</h5>
            <div class="list-group non-style mb-4 mb-md-0">
              <Link
                href="#"
                class="list-group-item list-group-item-action"
                aria-current="true"
              >
                Blog
              </Link>
              <Link href="#" class="list-group-item list-group-item-action">
                UPSC Syllabus 2022-2023
              </Link>
              <Link href="#" class="list-group-item list-group-item-action">
                FAQs
              </Link>
              <Link href="#" class="list-group-item list-group-item-action">
                Course Developer/Part-time Faculty
              </Link>
              <Link
                class="list-group-item list-group-item-action"
                aria-disabled="true"
              >
                IALM Instituional Collaborations
              </Link>
              <Link
                to="/faculty-registration"
                class="list-group-item list-group-item-action"
                aria-disabled="true"
              >
                Course Developer/Part-time Faculty
              </Link>
            </div>
          </div>
          <div class="col-md-3">
            <h5 class="mb-0">Policies & Contact</h5>
            <div class="list-group non-style mb-4 mb-md-0">
              <Link
                href="#"
                class="list-group-item list-group-item-action"
                aria-current="true"
              >
                Refund policy
              </Link>
              <Link href="#" class="list-group-item list-group-item-action">
                Terms Of Use
              </Link>
              <Link href="#" class="list-group-item list-group-item-action">
                Privacy Policy
              </Link>
              <Link href="#" class="list-group-item list-group-item-action">
                Contact us
              </Link>
              <Link
                class="list-group-item list-group-item-action"
                aria-disabled="true"
              >
                IPR and Innovation Cell
              </Link>
            </div>
          </div>

          <div class="col-md-3">
            <h5 class="mb-0">Policies & Contact</h5>
            <div class="list-group non-style mb-4 mb-md-0">
              <Link
                href="#"
                class="list-group-item list-group-item-action"
                aria-current="true"
              >
                Refund policy
              </Link>
              <Link href="#" class="list-group-item list-group-item-action">
                Terms Of Use
              </Link>
              <Link href="#" class="list-group-item list-group-item-action">
                Privacy Policy
              </Link>
              <Link href="#" class="list-group-item list-group-item-action">
                Contact us
              </Link>
              <Link
                class="list-group-item list-group-item-action"
                aria-disabled="true"
              >
                IPR and Innovation Cell
              </Link>
            </div>
          </div>

          <div class="col-md-3">
            <h5 class="mb-0">Contact us</h5>
            <div class="list-group non-style mb-4 mb-md-0">
              <Link
                href="#"
                class="list-group-item list-group-item-action"
                aria-current="true"
              >
                81, National Park, Lajpat Nagar-IV, New Delhi, Delhi 110024,
                India
              </Link>
              <Link href="#" class="list-group-item list-group-item-action">
                Call: +91 96507 27918
              </Link>
              <Link href="#" class="list-group-item list-group-item-action">
                TollFree: 18002586884
              </Link>
              <Link href="#" class="list-group-item list-group-item-action">
                WHATSAPP NUMBER : +91 9650727918
              </Link>
              <Link
                class="list-group-item list-group-item-action"
                aria-disabled="true"
              >
                registrar@ialm.academy
              </Link>
            </div>
          </div>

          <div class="col-12 border-op">
            <div class="footer-bottom d-flex flex-column-reverse flex-md-row justify-content-between mt-5 border-top border-1 pt-3 pt-md-5">
              <div class="mt-3 mt-md-0">
                <p class="text-center text-md-left">
                  &copy; 2024 IALM. All rights reserved.
                </p>
              </div>

              <div class="d-flex justify-content-center justify-content-md-start">
                <Link href="#" class="social-icons text-decoration-none mx-2">
                  <FontAwesomeIcon icon={faFacebookF} className="" />
                </Link>
                <Link href="#" class="social-icons text-decoration-none mx-2">
                  <FontAwesomeIcon icon={faLinkedinIn} className="" />
                </Link>
                <Link href="#" class="social-icons text-decoration-none mx-2">
                  <FontAwesomeIcon icon={faTwitter} className="" />
                </Link>
                <Link href="#" class="social-icons text-decoration-none mx-2">
                  <FontAwesomeIcon icon={faYoutube} className="" />
                </Link>
                <Link href="#" class="social-icons text-decoration-none mx-2">
                  <FontAwesomeIcon icon={faInstagram} className="" />
                </Link>
                <Link href="#" class="social-icons text-decoration-none mx-2">
                  <FontAwesomeIcon icon={faTiktok} className="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

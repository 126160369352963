import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./../styles/info_tooltip.style.css";

export default function InfoTooltip({ tooltip, classes = "" }) {
  return (
    <span className={`help-tip ${classes}`} title={tooltip}>
      <FontAwesomeIcon icon={faInfo} color="#ffffff" fontSize={14} />
    </span>
  );
}

import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/font-awesome/css/font-awesome.min.css";

import "./App.css";

import HomePage from "./pages/HomePage";
import Header from "./sections/Header";
import Footer from "./sections/Footer";

import UnderDevelopmentPage from "./pages/UnderDevelopmentPage";
import FacultyEnrollmentPage from "./pages/FacultyEnrollmentPage";
import ScrollToTop from "./component/ScrollTotop";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <ScrollToTop />

        <Routes>
          <Route path="/" exact element={<HomePage />}></Route>

          <Route
            path="/under-development"
            element={<UnderDevelopmentPage />}
          ></Route>

          <Route
            path="/faculty-registration"
            element={<FacultyEnrollmentPage />}
          ></Route>

          {/* <Route path="*" element={<NoPage />} /> */}
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;

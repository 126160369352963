import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./../styles/mobile_nav.style.css";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default function MobileNav() {
  return (
    <>
      <nav className="container-fluid mobile-header d-xl-none">
        <div className="w-100 py-2">
          <button
            type="button"
            onClick="openMenu()"
            className="btn btn-transparent"
          >
            <img
              src="/icons/menu-icon.svg"
              alt="menu-icon"
              className="_menu_icon"
            />
          </button>

          <a className="navbar-brand" href="#">
            <img
              src="logo.png"
              alt="logo"
              className="img-fluid"
              id="header-logo-mobile"
            />
          </a>
        </div>

        <div className="mobile-nav-wrap">
          <div className="_top d-flex justify-content-between bg-white p-3 align-items-center">
            <a href="#">
              <img
                src="logo.png"
                alt="logo"
                className="img-fluid"
                id="header-logo-mobile"
              />
            </a>
            <button
              type="button"
              className="btn btn-transparent"
              onClick="closeMenu()"
            >
              <img src="assets/icons/close-icon.svg" alt="menu-icon" />
            </button>
          </div>

          <div className="px-4">
            <ul className="list mt-2 px-0">
              <li>
                <a href="#" className="d-block text-decoration-none">
                  For business
                </a>
              </li>
              <li>
                <a href="#" className="d-block text-decoration-none">
                  For government
                </a>
              </li>
              <li>
                <a href="#" className="d-block text-decoration-none">
                  For universities
                </a>
              </li>
            </ul>
          </div>

          <div className="_bottom bg-light py-2 position-absolute">
            <a className="btn btn-primary theme-btn bg-primary me-2">
              Join for Free
            </a>

            <a className="btn btn-outline-primary theme-btn">
              Log in
              <span className="_icon">
                <FontAwesomeIcon icon={faArrowRight} />
              </span>
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}

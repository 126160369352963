import { Link } from "react-router-dom";
import "./../styles/desktop_nav.style.css";
import Searchbar from "./Searchbar";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useEffect, useState } from "react";

import AxiosClient from "./../client/AxiosClient";
import { API_ALL_CATEGORIES } from "./../ApiEndPoints";

import Loader from "./../component/Loader";

export default function DesktopNav() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    AxiosClient.get(API_ALL_CATEGORIES)
      .then((response) => {
        setData(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <>
      {/* <!-- lg navbar starts --> */}
      <nav className="navbar navbar-expand-lg navbar-light bg-white position-sticky d-none d-xl-block">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src="/logo.png"
              alt="logo"
              className="img-fluid"
              id="header-logo"
              width="60"
            />
          </Link>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <div className="d-flex">
              <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                <li className="nav-item dropdown ">
                  <DropdownButton
                    align="end"
                    title="Categories"
                    id="dropdown-menu-align-end"
                    className="btn border border-1 border-primary me-2 bg-transparant p-1 px-2 "
                    variant="transparent"
                  >
                    <div className="bg-lightaccent">
                      {loading === true ? (
                        <div className="text-center">
                          <Loader classes="text-primary" />
                        </div>
                      ) : (
                        data.map((row, index) => {
                          return (
                            <Dropdown.Item eventKey="1">
                              {row.name}
                            </Dropdown.Item>
                          );
                        })
                      )}

                      {/* <Dropdown.Item eventKey="2">
                        Patent Agent Examination
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="3">RTI</Dropdown.Item>
                      <Dropdown.Item eventKey="4">RTI</Dropdown.Item>
                      <Dropdown.Item eventKey="5">
                        Professional Politician
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="6">CLAT PG-LLM</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item eventKey="4">Separated link</Dropdown.Item> */}
                    </div>
                  </DropdownButton>
                </li>
              </ul>

              {/* <!-- search bar starts --> */}
              <Searchbar />
              {/* <!-- search bar ends --> */}
            </div>
          </div>

          {/* <!-- right menu starts --> */}

          <ul className="navbar-nav me-auto mb-2 mb-lg-0 _right_menu">
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                All Courses
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                About Us
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">
                Blog
              </a>
            </li>
            <li className="nav-item">
              <Link className="nav-link login-link" to="/?action=login">
                Login
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link btn btn-outline-primary signup-link"
                to="/?action=register"
              >
                Join For Free
              </Link>
            </li>
          </ul>
          {/* <!-- right menu ends --> */}
        </div>
      </nav>
      {/* <!-- lg navbar ends --> */}

      {/* <!-- mobile menu starts --> */}
    </>
  );
}

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_ALL_CATEGORIES, API_POPULAR_COURSES } from "../ApiEndPoints";
import AxiosClient from "../client/AxiosClient";

export default function CourseCategory() {
  const [data, setData] = useState([]);
  const serverUrl = process.env.REACT_APP_SERVER_BASE_URL;

  useEffect(() => {
    AxiosClient.get(API_ALL_CATEGORIES)
      .then((response) => {
        const listData = response.data.data;
        console.log(listData);
        setData(listData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <section class="pt-0">
      <div class="container">
        <h2 class="sec-title mb-4">Explore IALM</h2>

        <div class="row">
          {data.map((row, index) => {
            return (
              <div class="col-sm-6 col-md-6 col-lg-4">
                <Link to="#" class="text-decoration-none">
                  <div class="card d-flex flex-row tile-style-1 border-0 overflow-hidden my-2">
                    <div class="_img_wrap">
                      <img
                        src={row.thumbnail}
                        class="card-img-top"
                        alt={row.name}
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title mb-0">{row.name}</h5>
                      <p class="card-text _count">{row.courseCount} courses</p>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}

          {/* <div class="col-sm-6 col-md-6 col-lg-4">
            <Link to="#" class="text-decoration-none">
              <div class="card d-flex flex-row tile-style-1 border-0 overflow-hidden my-2">
                <div class="_img_wrap">
                  <img
                    src="/img/course-category/data_science.png"
                    class="card-img-top"
                    alt="..."
                  />
                </div>
                <div class="card-body">
                  <h5 class="card-title mb-0">Data Science</h5>
                  <p class="card-text _count">425 courses</p>
                </div>
              </div>
            </Link>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-4">
            <Link to="#" class="text-decoration-none">
              <div class="card d-flex flex-row tile-style-1 border-0 overflow-hidden my-2">
                <div class="_img_wrap">
                  <img
                    src="/img/course-category/health.png"
                    class="card-img-top"
                    alt="..."
                  />
                </div>
                <div class="card-body">
                  <h5 class="card-title mb-0">Data Science</h5>
                  <p class="card-text _count">500 courses</p>
                </div>
              </div>
            </Link>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4">
            <Link to="#" class="text-decoration-none">
              <div class="card d-flex flex-row tile-style-1 border-0 overflow-hidden my-2">
                <div class="_img_wrap">
                  <img
                    src="/img/course-category/computer_science.png"
                    class="card-img-top"
                    alt="..."
                  />
                </div>
                <div class="card-body">
                  <h5 class="card-title mb-0">Computer Science</h5>
                  <p class="card-text _count">300 courses</p>
                </div>
              </div>
            </Link>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-4">
            <Link to="#" class="text-decoration-none">
              <div class="card d-flex flex-row tile-style-1 border-0 overflow-hidden my-2">
                <div class="_img_wrap">
                  <img
                    src="/img/course-category/data_science.png"
                    class="card-img-top"
                    alt="..."
                  />
                </div>
                <div class="card-body">
                  <h5 class="card-title mb-0">Data Science</h5>
                  <p class="card-text _count">425 courses</p>
                </div>
              </div>
            </Link>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-4">
            <Link to="#" class="text-decoration-none">
              <div class="card d-flex flex-row tile-style-1 border-0 overflow-hidden my-2">
                <div class="_img_wrap">
                  <img
                    src="/img/course-category/health.png"
                    class="card-img-top"
                    alt="..."
                  />
                </div>
                <div class="card-body">
                  <h5 class="card-title mb-0">Data Science</h5>
                  <p class="card-text _count">500 courses</p>
                </div>
              </div>
            </Link>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-4">
            <Link to="#" class="text-decoration-none">
              <div class="card d-flex flex-row tile-style-1 border-0 overflow-hidden my-2">
                <div class="_img_wrap">
                  <img
                    src="/img/course-category/personal_development.png"
                    class="card-img-top"
                    alt="..."
                  />
                </div>
                <div class="card-body">
                  <h5 class="card-title mb-0">Data Science</h5>
                  <p class="card-text _count">Math and Logic courses</p>
                </div>
              </div>
            </Link>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4">
            <Link to="#" class="text-decoration-none">
              <div class="card d-flex flex-row tile-style-1 border-0 overflow-hidden my-2">
                <div class="_img_wrap">
                  <img
                    src="/img/course-category/computer_science.png"
                    class="card-img-top"
                    alt="..."
                  />
                </div>
                <div class="card-body">
                  <h5 class="card-title mb-0">Computer Science</h5>
                  <p class="card-text _count">300 courses</p>
                </div>
              </div>
            </Link>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-4">
            <Link to="#" class="text-decoration-none">
              <div class="card d-flex flex-row tile-style-1 border-0 overflow-hidden my-2">
                <div class="_img_wrap">
                  <img
                    src="/img/course-category/data_science.png"
                    class="card-img-top"
                    alt="..."
                  />
                </div>
                <div class="card-body">
                  <h5 class="card-title mb-0">Data Science</h5>
                  <p class="card-text _count">425 courses</p>
                </div>
              </div>
            </Link>
          </div>
          <div class="col-sm-6 col-md-6 col-lg-4">
            <Link to="#" class="text-decoration-none">
              <div class="card d-flex flex-row tile-style-1 border-0 overflow-hidden my-2">
                <div class="_img_wrap">
                  <img
                    src="/img/course-category/health.png"
                    class="card-img-top"
                    alt="..."
                  />
                </div>
                <div class="card-body">
                  <h5 class="card-title mb-0">Data Science</h5>
                  <p class="card-text _count">500 courses</p>
                </div>
              </div>
            </Link>
          </div> */}
        </div>
      </div>
    </section>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./../styles/searchbar.style.css";

import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

export default function Searchbar() {
  return (
    <div className="search-bar">
      <form className="d-flex position-relative">
        <input
          className="form-control me-2 _search-field"
          type="text"
          placeholder="Search for a course"
          aria-label="Search for a course"
        />
        <button
          className="btn btn-primary _search_submit position-absolute"
          type="submit"
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} className="_icon" />
        </button>
      </form>
    </div>
  );
}

import { Link } from "react-router-dom";

export default function Hero({ title, desc }) {
  return (
    <section className="hero pt-md-0 pb-0 pb-md-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <div>
              <h1 className="text-white mb-2 text-center text-md-start mt-lg-2 mt-xl-0">
                Unwrap new career skills. IALM Plus for INR 7999.
              </h1>
              <p className="text-white text-center text-md-start">
                Get unlimited access to 7,000+ learning programs from Google,
                Microsoft, IBM, and more, for just INR 7999.
              </p>
              <div className="d-flex justify-content-center justify-content-md-start">
                <Link to="#" className="btn btn-light theme-btn bg-light me-2">
                  Claim Your Offer
                </Link>
                <Link className="btn btn-outline-light theme-btn">
                  {" "}
                  Join For Free{" "}
                </Link>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <img src="/img/slides/slide.png" className="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
}

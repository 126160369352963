import DesktopNav from "../component/DesktopNav";
import MobileNav from "../component/MobileNav";
import { TopBar, TopBarItem } from "../component/TopBar";

export default function Header() {
  return (
    <>
      {/* <TopBar>
        <TopBarItem
          label="For Individuals"
          linkRef="/for-individuals"
        ></TopBarItem>

        <TopBarItem
          label="For Bussiness"
          linkRef="/for-individuals"
        ></TopBarItem>

        <TopBarItem
          label="For Univerties"
          linkRef="/for-individuals"
        ></TopBarItem>

        <TopBarItem
          label="For Goverments"
          linkRef="/for-individuals"
        ></TopBarItem>
      </TopBar> */}

      <DesktopNav />
      <MobileNav />
    </>
  );
}

import {
  faEye,
  faEyeSlash,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Form } from "react-bootstrap";
import "./../styles/toggle_password_field.style.css";

export default function TogglePasswordField({ passwordValue, setPassword }) {
  const [showPassword, setShowPassword] = useState(false);

  function togglePasswordVisibility() {
    setShowPassword(!showPassword);
  }

  return (
    <>
      <div className="position-relative toggle-password-field">
        <Form.Control
          type={showPassword === true ? "text" : "password"}
          placeholder=""
          value={passwordValue}
          onChange={(event) => setPassword(event.target.value)}
        />
        <span
          className="_icon position-absolute"
          role="button"
          onClick={togglePasswordVisibility}
        >
          <FontAwesomeIcon icon={showPassword === true ? faEye : faEyeSlash} />
        </span>
      </div>
    </>
  );
}

export default function UnderDevelopmentPage() {
  return (
    <main>
      <section>
        <div className="container text-center">
          <h3 className="text-center">
            Hi {localStorage.getItem("login_name")}, You are loggedin
          </h3>
          <img
            src="/under-development.png"
            alt="under development"
            style={{ maxWidth: "400px" }}
            className="mx-auto img-fuild"
          />
          <h4 className="mt-5">This page is in under development</h4>
        </div>
      </section>
    </main>
  );
}

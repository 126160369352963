import { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TogglePasswordField from "./TogglePasswordField";
import { Link, useNavigate } from "react-router-dom";

import "./../styles/login_form_model.style.css";
import Loader from "./Loader";
import AxiosClient from "../client/AxiosClient";
import { API_LOGIN } from "../ApiEndPoints";
import { SERVER_ERR_MSG } from "../DefaultMsg";
import DismissableAlert from "./DismissableAlert";

export default function LoginFormModal() {
  const navigateTo = useNavigate();

  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formValues, setFormValues] = useState({
    email_or_phone: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({
    email_or_phone: "",
    password: "",
  });

  const [showLoader, setShowLoader] = useState(false);
  const [showAlert, setshowAlert] = useState({
    show: false,
    type: "",
    title: "",
    desc: "",
  });

  function setPasswordField(password) {
    setFormValues({ ...formValues, password: password });
  }

  function handleSubmit(event) {
    event.preventDefault();

    setShowLoader(true);

    AxiosClient.post(API_LOGIN, formValues)
      .then((response) => {
        const { data: responseData } = response;

        if (responseData.success === true) {
          setFormErrors({
            email_or_phone: "",
            password: "",
          });

          setshowAlert({
            show: true,
            type: "success",
            title: "",
            desc: responseData.message,
          });

          localStorage.setItem("token", responseData.data.access_token);
          localStorage.setItem("login_name", responseData.data.profile.name);
          localStorage.setItem("login_email", responseData.data.profile.email);
          localStorage.setItem("login_phone", responseData.data.profile.phone);

          setTimeout(() => {
            navigateTo("/under-development");
          }, 1000);
        } else {
          setFormErrors({
            email_or_phone:
              responseData.errors.email_or_phone != null
                ? responseData.errors.email_or_phone
                : "",

            password:
              responseData.errors.password != null
                ? responseData.errors.password
                : "",
          });

          setshowAlert({
            show: true,
            type: "danger",
            title: "",
            desc: responseData.message,
          });

          console.log(showAlert);
        }
      })
      .catch((err) => {
        console.error(err);

        setshowAlert({
          show: true,
          type: "danger",
          title: "",
          desc: SERVER_ERR_MSG,
        });

        setShowLoader(false);
      })
      .finally(() => {
        setShowLoader(false);
      });

    console.log({ ...formValues });
  }

  return (
    <>
      <Modal
        className="login-modal"
        show={show}
        onHide={() => navigateTo("/")}
        centered
      >
        <Modal.Header className="border-0 pt-4 text-center" closeButton>
          <Modal.Title className="w-100">Welcome Back</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email/Mobile no.</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={formValues.email_or_phone}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    email_or_phone: event.target.value,
                  });
                }}
              />
              <span className="validation-err text-danger">
                {formErrors.email_or_phone}
              </span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Password</Form.Label>
              <TogglePasswordField
                password={formValues.password}
                setPassword={setPasswordField}
              />

              {formErrors.password !== "" && (
                <span className="validation-err text-danger d-block">
                  {formErrors.password}
                </span>
              )}

              <Link to="#" class="text-sm">
                Forget password?
              </Link>

              <span className="validation-err text-danger">
                {formErrors.password}
              </span>
            </Form.Group>

            {showAlert.show === true && (
              <DismissableAlert
                varient={showAlert.type}
                title={showAlert.title}
                desc={showAlert.desc}
              />
            )}

            <Button
              type="submit"
              className="btn btn-primary theme-btn bg-primary d-block w-100 mt-4"
              disabled={showLoader}
            >
              <span className="me-2">Login</span>
              {showLoader && <Loader classes="text-white" />}
            </Button>

            <p className="d-flex justify-content-center flex-wrap mt-4 text-sm">
              <span className="text-muted">
                New to {process.env.REACT_APP_NAME || "Our Platform"}?
              </span>
              <Button
                type="button"
                variant="transparent"
                className="p-0 m-0 ms-1 text-decoration-underline link"
                onClick={() => navigateTo("/?action=register")}
              >
                Sign up
                {showLoader && <Loader classes="text-white" />}
              </Button>
            </p>

            <hr className="my-4" />
            <p className="d-flex justify-content-center flex-wrap mt-3 text-sm">
              <span className="text-muted">Having trouble logging in?</span>
              <Button
                type="button"
                variant="transparent"
                className="p-0 m-0 ms-1 text-decoration-underline link"
              >
                Visit helpcenter
              </Button>
            </p>
          </Form>
        </Modal.Body>
        {/* <Modal.Footer className="pb-4">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
}

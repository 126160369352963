export default function TestimonialRow() {
  return (
    <section class="bg-white pt-0">
      <div class="container">
        <h2 class="sec-title text-center">From the IALM community</h2>
        <h3 class="sec-title-sm text-center fw-500 mb-5">
          148+ million people have already joined IALM
        </h3>

        <div class="row">
          <div class="col-md-4">
            <div class="card testimonial-card border-0 px-4 mb-4">
              <div class="avtar mx-auto">
                <img
                  src="/img/testimonial/Circle_Kenia.png"
                  class="card-img-top"
                  alt=""
                />
              </div>
              <div class="card-body text-center">
                <h4 class="card-title">Kenia R.</h4>
                <p class="card-city">United States</p>
                <span class="sep mx-auto"></span>

                <div class="_desc mt-3">
                  “Being a mother — especially a working mother means I’m
                  constantly trying to juggle my schedule, my kids’ schedules,
                  and work. I am very grateful for the flexible and remote
                  learning programs that IALM has to offer.”
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card testimonial-card border-0 px-4 mb-4">
              <div class="avtar mx-auto">
                <img
                  src="/img/testimonial/Circle_Ryan.png"
                  class="card-img-top"
                  alt=""
                />
              </div>
              <div class="card-body text-center">
                <h4 class="card-title">Kenia R.</h4>
                <p class="card-city">United States</p>
                <span class="sep mx-auto"></span>

                <div class="_desc mt-3">
                  “Being a mother — especially a working mother means I’m
                  constantly trying to juggle my schedule, my kids’ schedules,
                  and work. I am very grateful for the flexible and remote
                  learning programs that IALM has to offer.”
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="card testimonial-card border-0 px-4 mb-0">
              <div class="avtar mx-auto">
                <img
                  src="/img/testimonial/Vishal_Verma.png"
                  class="card-img-top"
                  alt=""
                />
              </div>
              <div class="card-body text-center">
                <h4 class="card-title">Kenia R.</h4>
                <p class="card-city">United States</p>
                <span class="sep mx-auto"></span>

                <div class="_desc mt-3">
                  “Being a mother — especially a working mother means I’m
                  constantly trying to juggle my schedule, my kids’ schedules,
                  and work. I am very grateful for the flexible and remote
                  learning programs that IALM has to offer.”
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

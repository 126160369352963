import { useSearchParams } from "react-router-dom";
import Hero from "../component/Hero";
import CourseCategory from "../sections/CourseCategory";
import CoursesGrid1 from "../sections/CoursesGrid1";
import CoursesRowWithAction from "../sections/CoursesRowWithAction";
import LogoRow from "../sections/LogoRow";
import TestimonialRow from "../sections/TestimonialRow";
import LoginFormModal from "../component/LoginFormModal";
import RegisterationFormModal from "../component/RegistrationFormModal";

export default function HomePage() {
  const [searchParams] = useSearchParams();
  const action = searchParams.get("action");

  console.log(action);

  return (
    <main>
      <Hero />
      <CoursesGrid1 />
      <LogoRow />
      <CoursesRowWithAction />
      <CourseCategory />
      <TestimonialRow />

      {action === "login" && <LoginFormModal />}
      {action === "register" && <RegisterationFormModal />}
    </main>
  );
}

export default function Loader({ classes = "" }) {
  return (
    <div
      class={`spinner-border btn-loader ${
        classes.trim() == "" ? "text-primary" : classes
      }`}
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
  );
}
